<template>
  <div>
    <b-modal
      id="modal-tall"
      ref="modalTermos"
      class="col-12"
      size="lg"
      title="Bem vindo à plataforma Sig-Mind!!!"
      cancel-variant="outline-secondary"
      cancel-title="Cancelar"
      centered
      ok-title="De acordo"
      @ok="aceitaTermos"
      @hidden="fecharModal"
    >
      <b-card-text>
        Após conferir seus dados cadastrais, você deverá acessar a plataforma e
        jogar os jogos disponíveis em <code>{{ quantidadeDeJogos }}</code> dias
        diferentes. É necessário seguir as recomendações a seguir:
      </b-card-text>
      <ul class="list-style-icons">
        <li>
          <feather-icon icon="ArrowRightIcon" />Estar com boa disposição física:
          descansado, alimentado e sem ter ingerido bebida alcoólica nas últimas
          24 horas.
        </li>
        <li>
          <feather-icon icon="ArrowRightIcon" />Estar com seus óculos ou lentes
          corretivas, caso faça uso.
        </li>
        <li>
          <feather-icon icon="ArrowRightIcon" />Não ser interrompido durante os
          jogos.
        </li>
      </ul>
      <br>
      <b-card-text>
        Clique
        <b-link
          class="font-weight-bold"
          target="_blank"
          href="http://sig-mind.com/documentos/politica_de_privacidade_sig-mind.pdf"
        >
          aqui
        </b-link>
        para ler o Termo de Uso e Condições Gerais para Usuários.
      </b-card-text>
      <b-card-text>
        Clique
        <b-link
          class="font-weight-bold"
          target="_blank"
          href="http://sig-mind.com/documentos/termo_de_uso_e_condicoes_gerais_para_usuarios.pdf"
        >
          aqui
        </b-link>
        para ler a Política de Privacidade Sig-Mind.
      </b-card-text>
      <br>
      <b-card-text>
        Ao clicar em De acordo, confirmo que li e estou de acordo com os termos
        acima.
      </b-card-text>
    </b-modal>
  </div>
</template>
<script>
import Ripple from "vue-ripple-directive"
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue"
import useJwt from "@/auth/jwt/useJwt"
import { getUserData } from "@/auth/utils"
import {
  BCard,
  BRow,
  BCol,
  BCardText,
  BModal,
  BLink
  // BButton,
} from "bootstrap-vue"
export default {
  components: {
    BCardText,
    BModal,
    BLink
  },
  data() {
    return {
      idDoPerfil: null,
      primeiroLogin: false,
      quantidadeDeJogos: ""
    }
  },
  mounted() {
    this.idDoPerfil = getUserData().idDoPerfil
    this.veriticaTermosJogos()
  },
  methods: {
    fecharModal() {
      this.logout()
    },
    async veriticaTermosJogos() {
      const resp = await useJwt
        .verificaTermos()
        .then(response => response.data)
        .catch(response => {
          const catchError = Object.assign({}, response)
          this.MensagemErro("danger", "top-center")
        })
      if (resp.sucesso) {
        this.primeiroLogin = resp.dados.primeiroLogin
        this.quantidadeDeJogos = resp.dados.quantidaDeJogos.toString().extenso()
        if (resp.dados.primeiroLogin == true) {
          if (this.idDoPerfil > 2) {
            this.$refs.modalTermos.show()
          }
        }
      }
    },
    async aceitaTermos() {
      // this.dataTermos = this.mostrarData()
      const resp = await useJwt
        .salvarTermosJogos({
          // dataTermos: this.dataTermos
        })
        .then(response => response.data)
        .catch(response => {
          const catchError = Object.assign({}, response)
          this.MensagemErro("danger", "top-center")
        })
      if (resp.sucesso) {
        this.primeiroLogin = false
        this.MensagemCadastradoTermosSucesso("success", "top-center")
      }
    },
    logout() {
      if (this.primeiroLogin == true) {
        localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
        localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName)
        localStorage.removeItem("userData")
        // this.$ability.update(initialAbility)
        this.$router.push({ name: "auth-login" }).catch(() => {})
      }
    },
    MensagemCadastradoTermosSucesso(variant, position) {
      const nomeDoUsuario = getUserData().nomeDoUsuario
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: "Termos aceitos " + nomeDoUsuario,
            icon: "GiftIcon",
            text: "Agora você poderá jogar!",
            variant
          }
        },
        {
          position
        }
      )
    },
    MensagemErro(variant, position) {
      const nomeDoUsuario = getUserData().nomeDoUsuario
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: "Ocorreu um erro na requisição",
            icon: "AlertTriangleIcon",
            text: "Por favor, tente novamente mais tarde.",
            variant
          }
        },
        {
          position
        }
      )
    }
  }
}
// função converter numero para extenso
String.prototype.extenso = function(c) {
  var ex = [
    [
      "zero",
      "um",
      "dois",
      "três",
      "quatro",
      "cinco",
      "seis",
      "sete",
      "oito",
      "nove",
      "dez",
      "onze",
      "doze",
      "treze",
      "quatorze",
      "quinze",
      "dezesseis",
      "dezessete",
      "dezoito",
      "dezenove"
    ],
    [
      "dez",
      "vinte",
      "trinta",
      "quarenta",
      "cinquenta",
      "sessenta",
      "setenta",
      "oitenta",
      "noventa"
    ],
    [
      "cem",
      "cento",
      "duzentos",
      "trezentos",
      "quatrocentos",
      "quinhentos",
      "seiscentos",
      "setecentos",
      "oitocentos",
      "novecentos"
    ],
    [
      "mil",
      "milhão",
      "bilhão",
      "trilhão",
      "quadrilhão",
      "quintilhão",
      "sextilhão",
      "setilhão",
      "octilhão",
      "nonilhão",
      "decilhão",
      "undecilhão",
      "dodecilhão",
      "tredecilhão",
      "quatrodecilhão",
      "quindecilhão",
      "sedecilhão",
      "septendecilhão",
      "octencilhão",
      "nonencilhão"
    ]
  ]
  var a,
    n,
    v,
    i,
    n = this.replace(c ? /[^,\d]/g : /\D/g, "").split(","),
    e = " e ",
    $ = "real",
    d = "centavo",
    sl
  for (
    var f = n.length - 1, l, j = -1, r = [], s = [], t = "";
    ++j <= f;
    s = []
  ) {
    j && (n[j] = (("." + n[j]) * 1).toFixed(2).slice(2))
    if (
      !((a = (v = n[j]).slice((l = v.length) % 3).match(/\d{3}/g)),
      (v = l % 3 ? [v.slice(0, l % 3)] : []),
      (v = a ? v.concat(a) : v)).length
    )
      continue
    for (a = -1, l = v.length; ++a < l; t = "") {
      if (!(i = v[a] * 1)) continue
      ;(i % 100 < 20 && (t += ex[0][i % 100])) ||
        ((i % 100) + 1 &&
          (t +=
            ex[1][(((i % 100) / 10) >> 0) - 1] +
            (i % 10 ? e + ex[0][i % 10] : "")))
      s.push(
        (i < 100
          ? t
          : !(i % 100)
          ? ex[2][i == 100 ? 0 : (i / 100) >> 0]
          : ex[2][(i / 100) >> 0] + e + t) +
          ((t = l - a - 2) > -1
            ? " " + (i > 1 && t > 0 ? ex[3][t].replace("ão", "ões") : ex[3][t])
            : "")
      )
    }
    a =
      (sl = s.length) > 1
        ? ((a = s.pop()), s.join(" ") + e + a)
        : s.join("") || ((!j && n[j + 1] * 1 > 0) || r.length ? "" : ex[0][0])
    a &&
      r.push(
        a +
          (c
            ? " " +
              (v.join("") * 1 > 1
                ? j
                  ? d + "s"
                  : (/0{6,}$/.test(n[0]) ? "de " : "") + $.replace("l", "is")
                : j
                ? d
                : $)
            : "")
      )
  }
  return r.join(e)
}
</script>
